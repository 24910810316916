@import "../../Sass/_mixins.scss";
@import "../../Sass/_variables.scss";

.btn__container {
  @include flex-container;
  margin-top: 8rem;
  // transform: translateY(-3rem);
}

.classic {
  height: 40rem;
  width: 60rem;
  position: relative;
  box-shadow: 5px 3px 3px rgba(0, 0, 0, 20%);
}

.icon {
  margin-right: 1rem;
  height: 2rem;
  width: 2rem;

  &__checkEngine {
    height: 2.2rem;
  }
  
  &__brakes {
    height: 2.2rem;
  }
}

.img__container {
  @include flex-container;
  
  & > * {
    min-width: 20rem;
    width: 60rem;
    border-radius: 8px;
    margin: 3rem 0;
  }
}

.parent_flex__container {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-around;
  margin: 2rem 0;
  flex-wrap: wrap;
}

.phone__icon {
  margin-right: 5px;
  height: 2rem;
  width: 2rem;
}

.service {
  list-style-type: none;
  margin: 1rem 0;
}

.services {
  font-size: 4rem;
  text-align: center;
  font-family: $header-font;

  &__container {
    display: block;
    position: relative;
    justify-content: center;
    flex-direction: column;
    font-size: 2rem;
    text-align: left;
  }

  &__section {
    padding: $padding-section;
    background-color: $color-white-1;
  }
}

ul {
  padding-left: 0 !important;
}

@media screen and (min-width: 2560px) {
  .services {
    font-size: 7rem;

    &__container {
      font-size: 3rem;
    }
  }

  .phone__icon {
    height: 3rem;
    width: 3rem;
  }
}

@media screen and (max-width: 1200px) {
  .btn {
    display: inline-block;
    padding: 12px 24px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    border-radius: 4px;
    border: 2px solid #2c3e50;
    color: #2c3e50;
    background-color: #fff;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  .btn:hover {
    background-color: #2c3e50;
    color: #fff;
  }
  


  .services__section {
    padding: $padding-section-md;
  }
}

@media screen and (max-width: 1050px) {
  .btn {
    &__container {
      transform: translateY(6rem);
    }
  }
}

@media screen and (max-width: 900px) {
  .btn {
    font-size: 1.5rem;

    &__container {
      padding: 0;
      transform: translateY(-3rem);
    }
  }

  .services__section {
    padding: $padding-section-sm;
  }
}

@media screen and (max-width: 800px) {
  .btn {
    &__container {
      transform: translateY(0);
    }
  }
}

@media screen and (max-width: 600px) {
  .btn__container {
    margin-top: 2rem;
    transform: translateY(3rem);
  }

  .classic  {
    width: 30rem;
    height: 20rem;
  }

  .services__section {
    padding: 6rem 0;
  }
}

@media only screen and (max-width: 390px) {
  .classic {
    height: 23rem;
    width: 30rem;
    position: relative;
  }

  .parent_flex__container {
    margin: 0;
  }
}


@media only screen and (max-width: 300px) {
  .classic {
    height: 18rem;
    width: 24rem;
  }
}
